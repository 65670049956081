import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useAtom } from "jotai";
import { sideBarAtom } from "../../atoms/side-bar-atom";
import { classNames } from "primereact/utils";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface SideBarItemProps {
  to: string;
  label: string;
  icon: IconProp;
}
export function SideBarNavigationItemDesktop({
  to,
  label,
  icon,
}: SideBarItemProps) {
  const { t } = useTranslation();
  const [sidebarState] = useAtom(sideBarAtom);

  const location = useLocation();

  const isActive = useMemo(
    () => location.pathname.includes("/app/" + to),
    [location.pathname, to]
  );

  return (
    <div
      style={{ padding: "0.15rem 0.5rem " }}
      className="overflow-hidden transition-duration-500 animation-ease-in-out"
    >
      <NavLink
        style={{
          width: !sidebarState.expanded ? "3.25rem" : "calc(100%)",
          transitionProperty: "width, height",
          color: "#6F6072",
        }}
        className={({ isActive }) =>
          classNames(
            "text-center tooltip no-underline text-sm inline-flex align-items-center flex-nowrap border-round-xl transition-duration-500 animation-ease-in-out",
            isActive ? "bg-primary shadow-2" : "",
            "justify-content-start tooltip-default"
          )
        }
        data-pr-tooltip={sidebarState.expanded ? undefined : t(label)}
        to={to}
      >
        <div
          style={{ width: "3.25rem", height: "2.75rem" }}
          className="flex justify-content-center align-items-center"
        >
          <FontAwesomeIcon
            icon={icon}
            className={classNames(
              "fa-2xl",
              isActive ? "text-white" : "text-gray-600"
            )}
          />
        </div>
        <div
          style={{
            width: "calc(100% - 3.5rem)",
          }}
          className={classNames(
            "font-medium text-base overflow-hidden white-space-nowrap text-left transition-none",
            isActive ? "text-white" : "text-gray-600"
          )}
        >
          {t(label)}
        </div>
      </NavLink>
    </div>
  );
}
