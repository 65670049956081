import { classNames } from "primereact/utils";
import { ReactNode, useMemo } from "react";
import * as Yup from "yup";

export interface FormInputLabelProps {
  nameFor?: string;
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, "">;
  children?: ReactNode;
  isInline?: boolean;
  forceIsRequired?: boolean | undefined;
}

export function FormInputLabel({
  nameFor,
  validationSchema,
  children,
  forceIsRequired = undefined,
  isInline = false,
}: FormInputLabelProps) {
  const isRequired = useMemo(() => {
    if (!validationSchema || !nameFor) {
      return false;
    }

    if (forceIsRequired !== undefined) {
      return forceIsRequired;
    }

    try {
      validationSchema.validateSyncAt(nameFor, {});
      return false; // Field is not required (no validation error thrown)
    } catch (validationError) {
      return true; // Field is required (validation error thrown)
    }
  }, [validationSchema, nameFor, forceIsRequired]);

  return (
    <label
      htmlFor={nameFor}
      className={classNames("font-medium m-1", {
        "w-full": !isInline,
      })}
    >
      {children}
      <span className="text-red-500">{isRequired ? " *" : ""}</span>
    </label>
  );
}
