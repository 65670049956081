import { Button } from "primereact/button";

interface TableHeaderProps {
  header: string;
  buttonLabel?: string;
  buttonIcon?: string;
  showButton: boolean;
  buttonDisabled?: boolean;
  middleContent?: () => React.ReactNode;
  rightContent?: () => React.ReactNode;
  onClick?: () => void;
}

export function TableHeader({
  header,
  buttonLabel = "Add",
  buttonIcon = "pi pi-plus",
  showButton = false,
  buttonDisabled = false,
  middleContent,
  rightContent,
  onClick,
}: TableHeaderProps) {
  return (
    <div className="flex justify-content-between">
      <div className="text-xl font-bold">{header}</div>
      {middleContent && middleContent()}
      {showButton && (
        <div className="flex gap-2">
          <Button
            label={buttonLabel}
            icon={buttonIcon}
            onClick={onClick}
            disabled={buttonDisabled}
          />
          {rightContent && rightContent()}
        </div>
      )}
    </div>
  );
}
