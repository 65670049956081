import { MenuItem } from "./bar-navigation";
import { useAtom } from "jotai";
import { sideBarAtom, sideBarMoreAtom } from "../../atoms/side-bar-atom";
import { classNames } from "primereact/utils";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SideBarNavigationItemsDesktop } from "./side-bar-navigation-items-desktop";
import { useWindowSize } from "../../hooks/use-window-size";
import { useCallback, useEffect, useMemo } from "react";

export function SideBarNavigationDesktop({
  menuItems,
}: {
  menuItems: MenuItem[];
}) {
  const { height } = useWindowSize();

  const calculateMenuItems = useCallback(
    (menuItems: MenuItem[], leftMenuHeight: number) => {
      let ITEM_HEIGHT = 50;
      let MAX_ITEMS = (leftMenuHeight - 2 * ITEM_HEIGHT) / ITEM_HEIGHT;
      let visibleItems = menuItems.slice(0, MAX_ITEMS);
      let moreItems = menuItems.slice(MAX_ITEMS);
      return { visibleItems, moreItems };
    },
    []
  );

  const { visibleItems, moreItems } = useMemo(() => {
    return calculateMenuItems(menuItems, height);
  }, [menuItems, height, calculateMenuItems]);

  const [sidebarState, setSidebarState] = useAtom(sideBarAtom);
  const [sidebarMoreState, setSidebarMoreState] = useAtom(sideBarMoreAtom);

  useEffect(() => {
    setSidebarMoreState({ expanded: moreItems.length >= 1 });
  }, [moreItems.length, setSidebarMoreState]);

  return (
    <div className="flex z-5 h-full w-full">
      <div
        style={{
          height: "calc(100% - 1rem)",
          width: "calc(100% - 1rem)",
          margin: "0.5rem",
        }}
        className={classNames(
          "shadow-3 justify-content-between border-round flex flex-column"
        )}
      >
        <div className="flex flex-row transition-duration-500 animation-ease-in-out">
          <div
            className="transition-duration-500 animation-ease-in-out"
            style={{
              width: !sidebarState.expanded ? "4.25rem" : "12.5rem",
            }}
          >
            <SideBarNavigationItemsDesktop menuItems={visibleItems} />
          </div>
          {sidebarMoreState.expanded && (
            <div
              className="transition-duration-500 animation-ease-in-out"
              style={{
                width: !sidebarState.expanded ? "4.25rem" : "12.5rem",
              }}
            >
              <SideBarNavigationItemsDesktop menuItems={moreItems} />
            </div>
          )}
        </div>
        <div
          className={classNames(
            " flex justify-content-evenly p-2",
            sidebarState.expanded ? "" : "rotate-180"
          )}
        >
          <FontAwesomeIcon
            onClick={() =>
              setSidebarState({ expanded: !sidebarState.expanded })
            }
            icon={faCircleChevronLeft}
            style={{ borderRadius: "20px" }}
            className="text-gray-600 transition-duration-300 ease-in-out fa-2xl shadow-3 cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
}
