import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ErrorWithIconText(props: any) {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: "2s" }}
        className="px-2 mt-1"
        style={{
          borderRadius: "5px",
          border: "1px solid #FD9B9B",
          backgroundColor: "#FB2929",
          color: "#F6C7C7",
          fontSize: "0.75rem",
          paddingTop: "0.15rem",
          paddingBottom: "0.15rem",
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="pr-2 text-sm"
        ></FontAwesomeIcon>

        {props.children}
      </motion.div>
    </AnimatePresence>
  );
}

export default ErrorWithIconText;
