import { useEffect, useMemo, useState } from "react";
import {
  useGetUserByTokenQuery,
  useResetPasswordMutation,
} from "../../queries/models/_shared/auth.query";
import { Card } from "primereact/card";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "../../components/ui/toast-context-provider";
import { classNames } from "primereact/utils";
import { motion } from "framer-motion";

export function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get("t");
  const toast = useToast();
  const [password, setPassword] = useState<string>();
  const [passwordConfirmed, setPasswordConfirmed] = useState<string>();

  const query = useGetUserByTokenQuery(token!);
  const mutation = useResetPasswordMutation();
  const navigate = useNavigate();

  const isPasswordMatched = useMemo(
    () =>
      passwordConfirmed === undefined ||
      passwordConfirmed === null ||
      password === passwordConfirmed,
    [password, passwordConfirmed]
  );
  const isFormValid = useMemo(
    () => password && passwordConfirmed && isPasswordMatched,
    [password, passwordConfirmed, isPasswordMatched]
  );

  useEffect(() => {
    if (query.isError) {
      toast.current?.show({
        severity: "error",
        detail: "Token expired!",
      });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [navigate, query.data, query.isError, toast]);

  function executeReset() {
    setIsLoading(true);
    mutation.mutate(
      {
        password: password!,
        token: token!,
        userId: query.data?.id!,
      },
      {
        onSuccess: (response) => {
          toast.current?.show({
            severity: "success",
            detail: "Reset success, now login with new password.",
          });
          setTimeout(() => {
            navigate("/");
          }, 2000);
        },
        onError: (error) => {
          toast.current?.show({
            severity: "error",
            detail: error?.toString(),
          });
          console.error(error);
        },
        onSettled: (x, y, z, c) => {
          setIsLoading(false);
        },
      }
    );
  }

  return (
    <div className="grid grid-nogutter overflow-hidden">
      <motion.div
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: "backInOut" }}
        className="absolute flex h-full justify-content-center align-items-center"
        style={{
          height: "100%",
          width: "100%",
          zIndex: 1,
          background:
            "linear-gradient(93deg, rgba(124,77,255,0.5) 39%, rgba(193,163,236,0.05) 81%)",
        }}
      >
        <Card className="w-20rem border-round-2xl">
          <h2 className="mt-1 text-center">Reset password form</h2>
          {query.data && (
            <span className="p-float-label mb-4">
              {`Hello ${query.data.username}!`}
            </span>
          )}
          {query.isFetching && (
            <span className="p-float-label">Loading data..</span>
          )}
          <div className="mb-2">
            <div
              className="w-full font-semibold mb-1 text-primary"
              style={{}}
            >
              New password
            </div>
            <Password
              id="password"
              inputClassName="w-full"
              className="w-full"
              value={password ?? ""}
              onChange={(e) => setPassword(e.target.value)}
              feedback={false}
              toggleMask
            />
          </div>
          <div className="mb-2">
            <div
              className="w-full font-semibold mb-1 text-primary"
              style={{}}
            >
              Confirm password
            </div>
            <Password
              id="passwordConfirmed"
              className={classNames(
                { "p-invalid": !isPasswordMatched },
                "w-full"
              )}
              inputClassName="w-full"
              value={passwordConfirmed ?? ""}
              onChange={(e) => setPasswordConfirmed(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? executeReset() : {})}
              toggleMask
              feedback={false}
            />
          </div>
          {!isPasswordMatched && (
            <div className=" text-left text-red-600">Password not match</div>
          )}

          <Button
            label="Send request"
            loading={isLoading}
            className="bg-primary mt-4 w-full"
            onClick={executeReset}
            disabled={!isFormValid}
          />
        </Card>
      </motion.div>
    </div>
  );
}
