import { MenuItem } from "./bar-navigation";
import { SideBarNavigationItemDesktop } from "./side-bar-navigation-item-desktop";

export function SideBarNavigationItemsDesktop({
  menuItems,
}: {
  menuItems: MenuItem[];
}) {
  return (
    <ul className="p-0 m-0 mt-1 mb-1 w-full">
      {menuItems.map((x, i) => (
        <SideBarNavigationItemDesktop
          to={x.to}
          label={x.label}
          icon={x.icon}
          key={i}
        />
      ))}
    </ul>
  );
}
