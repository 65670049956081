import { useMemo } from "react";
import {
  Field,
  ErrorMessage,
  FastField,
  FormikContextType,
  FieldMetaProps,
  FieldInputProps,
} from "formik";
import { InputText, InputTextProps } from "primereact/inputtext";
import TextError from "./TextError";
import { FormInputLabel } from "./FormInputLabel";
import { classNames } from "primereact/utils";
import * as Yup from "yup";
import ErrorWithIconText from "./ErrorWithIconText";

export interface FormikInputTextPros extends InputTextProps {
  label: string;
  name: string;
  password?: boolean;
  isIndependent?: boolean;
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, "">;
  withIcon?: boolean;
  isRequired?: boolean;
}

function FormikInputText({
  label,
  name,
  password = false,
  isIndependent = false,
  validationSchema,
  withIcon = false,
  isRequired = undefined,
  ...rest
}: FormikInputTextPros) {
  const Component = useMemo(() => {
    return isIndependent ? FastField : Field;
  }, [isIndependent]);

  return (
    <>
      <FormInputLabel
        nameFor={name}
        validationSchema={validationSchema}
        forceIsRequired={isRequired}
      >
        {label}
      </FormInputLabel>
      <Component name={name}>
        {({
          form,
          field,
          meta,
        }: {
          form: FormikContextType<any>;
          field: FieldInputProps<string>;
          meta: FieldMetaProps<string>;
        }) => {
          return (
            <InputText
              id={name}
              className={classNames({
                "w-full": true,
                "p-invalid": meta.error && meta.touched,
              })}
              type={password ? "password" : "text"}
              placeholder={label}
              value={field.value}
              onBlur={(e) => form.setFieldTouched(name, true)}
              onChange={(e) => {
                form.setFieldValue(name, e.target.value);
              }}
              {...rest}
            />
          );
        }}
      </Component>
      <ErrorMessage
        component={withIcon ? ErrorWithIconText : TextError}
        name={name}
      />
    </>
  );
}

export default FormikInputText;
