
export class RandomGenerator {
    public static getRandomColor(): string {
        return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    }

    public static getSequencedColor(index: number): string {
        const colors = [
            "#3f51b5",
            "#f44336",
            "#4caf50",
            "#ff9800",
            "#2196f3",
            "#ff5722",
            "#9c27b0",
            "#795548",
            "#607d8b",
            "#00bcd4",
            "#8bc34a",
            "#cddc39",
            "#ffc107",
            "#ffeb3b",
            "#03a9f4",
        ];

        return colors[index % colors.length];
    }
}
