import { useQuery } from "react-query";
import { DeviceReading } from "./models/device-reading.model";
import { devicesReadingsService } from "../services/device-readings.service";


export function useGetDataReadingsForSeriesQuery(dateFrom: Date | undefined, dateTo: Date | undefined, deviceIds: number[] | undefined) {
    return useQuery<DeviceReading[] | undefined>(
        ["device-readings", dateFrom, dateTo, deviceIds],
        () => devicesReadingsService.getReadingsForSeries(dateFrom!, dateTo!, deviceIds!),
        { refetchOnWindowFocus: false, enabled: dateFrom !== undefined && dateTo !== undefined && deviceIds !== undefined && deviceIds.length > 0 }
    );
}