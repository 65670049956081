import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";
import { classNames } from "primereact/utils";

export interface LoaderProps {
  className?: string;
  hidden?: boolean;
  value?: number;
  type: "spinner" | "progressBar";
}

export function Loader(props: LoaderProps) {
  return (
    <>
      {props.value !== undefined && (
        <ProgressBar
          className={classNames(
            "fade-in progress-bar",
            props.className,
            props.hidden && "back"
          )}
          value={~~props.value}
        />
      )}
      {props.type === "spinner" && (
        <ProgressSpinner
          className={classNames(
            "fade-in flex purple-spinner",
            props.className,
            props.hidden && "back"
          )}
          strokeWidth="4"
        />
      )}
      {props.type === "progressBar" && props.value === undefined && (
        <ProgressBar
          className={classNames(
            "fade-in progress-bar",
            props.className,
            props.hidden && "back"
          )}
          mode="indeterminate"
        />
      )}
    </>
  );
}
