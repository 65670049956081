import { Button } from "primereact/button";
import React, { ReactNode, useEffect, useState } from "react";
import "./mobile-modal-styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { classNames } from "primereact/utils";

export interface CustomModalProps {
  isOpen: boolean;
  isModalForceFaded?: boolean;
  header: string;
  onClose(): void;
  confirmation?: boolean;
  onConfirm?(): void;
  body?: ReactNode | string;
  headerBody?: ReactNode | string;
  centered?: boolean;
  justified?: boolean;
  width?: string;
  maxWidth?: string;
  height?: string;
  zIndex?: "auto" | "1" | "2" | "3" | "4" | "5";
}

export function CustomModal({
  isOpen,
  isModalForceFaded = false,
  onClose,
  onConfirm,
  body,
  centered,
  justified,
  header: title,
  headerBody,
  confirmation = false,
  width,
  maxWidth,
  height,
  zIndex,
}: CustomModalProps) {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      setIsVisible(true);
    } else if (isOpen && isModalForceFaded) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [isOpen, isModalForceFaded]);

  const closeModal = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
      isModalForceFaded = false;
    }, 400);
  };

  const backgroundClick = (e: any) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };
  return (
    <>
      {isOpen && (
        <div
          onClick={backgroundClick}
          className={classNames(
            "bg-dialog-blur card-wrapper",
            isVisible ? "grey-in" : "grey-out",
            centered ? "centered" : "",
            justified ? "justified" : "",
            zIndex ? `z-${zIndex}` : "auto"
          )}
        >
          <div
            style={{ width: width, maxWidth: maxWidth, height: height }}
            className={classNames(
              "card",
              isVisible ? "slide-up" : "slide-down"
            )}
          >
            {headerBody && <>{headerBody}</>}
            {!headerBody && (
              <div className="header">
                <div className="title">{title}</div>
                <div className="close-btn cursor-pointer">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={closeModal}
                  />
                </div>
              </div>
            )}
            <div className="body">{body}</div>
            {confirmation && (
              <div className="footer">
                <Button
                  className="yellow-button"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  className="green-action-button"
                  onClick={onConfirm}
                >
                  Confirm
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
