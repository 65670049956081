import { useMemo, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faUser,
  faBook,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { authService } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { CustomModal, CustomModalProps } from "../ui/MobileModal/custom-modal";
import logo from "../../assets/icons/logo-white.svg";
import { Tooltip } from "primereact/tooltip";
import { motion } from "framer-motion";
import { useWindowSize } from "../../hooks/use-window-size";
import { useTranslation } from "react-i18next";

export function TopBarNavigation() {
  const { t } = useTranslation();
  const { lg } = useWindowSize();
  const navigate = useNavigate();
  const [isLogoutConfirmationModalOpen, setIsLogoutConfirmationModalOpen] =
    useState<boolean>(false);

  const logoutConfirmationModalProps: CustomModalProps = useMemo(() => {
    return {
      header: t("common.confirmation"),
      body: t("dialog.doYouReallyWantToLogout"),
      height: "160px",
      isOpen: isLogoutConfirmationModalOpen,
      confirmation: true,
      centered: true,
      justified: true,
      onClose: () => setIsLogoutConfirmationModalOpen(false),
      onConfirm: () => {
        setIsLogoutConfirmationModalOpen(false);
        authService.logout();
        navigate("/");
      },
    };
  }, [t, isLogoutConfirmationModalOpen, navigate]);

  const loggedUser = authService.getLoggedUser();

  const toolTipOffset = (loggedUser?.username?.length ?? 0) * -8.5 - 25;

  return (
    <>
      <div
        className="bg-primary flex"
        style={{ height: "50px" }}
      >
        <Tooltip
          target=".noBilling"
          position="mouse"
          mouseTrackLeft={10}
          mouseTrackTop={10}
          mouseTrack={true}
        />
        <Tooltip
          target=".username"
          position="mouse"
          mouseTrackLeft={toolTipOffset}
          mouseTrack={true}
        />
        <Tooltip
          target=".documentation"
          position="mouse"
          mouseTrackLeft={-175}
          mouseTrack={true}
        />

        <CustomModal {...logoutConfirmationModalProps} />
        <div className="flex align-items-center w-4">
          <div className="flex pl-3">
            <div className="justify-content-center flex"></div>
            <div className="text-3xl font-bold text-white no-underline ml-2">
              Pudliszki BI
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-content-end px-1 w-full align-items-center gap-1">
          <span
            className="username"
            data-pr-tooltip={`${loggedUser?.username}`}
          >
            <FontAwesomeIcon
              icon={faUser}
              className="mr-2 p-2 border-circle cursor-pointer"
              style={{
                backgroundColor: "white",
                color: "#3f51b5",
                width: "20px",
                height: "20px",
              }}
            />
          </span>
          <span onClick={() => setIsLogoutConfirmationModalOpen(true)}>
            <FontAwesomeIcon
              icon={faPowerOff}
              className="mr-2 p-2 border-circle cursor-pointer"
              style={{
                backgroundColor: "white",
                color: "#3f51b5",
                width: "20px",
                height: "20px",
              }}
            />
          </span>
        </div>
      </div>
    </>
  );
}
