import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "primereact/utils";
import { useMemo } from "react";

interface BottomBarNavigationMobileItemProps {
  to: string;
  label: string;
  icon: IconProp;
  isSituatedOnSidebar?: boolean;
}

export function BottomBarNavigationMobileItem({
  to,
  label,
  icon,
  isSituatedOnSidebar = false,
}: BottomBarNavigationMobileItemProps) {
  const location = useLocation();
  const isActive = useMemo(
    () => location.pathname.substring(1).startsWith("app/" + to),
    [location.pathname, to]
  );

  return (
    <>
      <li>
        <NavLink
          to={to}
          className="custom-nav-link">
          <FontAwesomeIcon
            icon={icon}
            className={classNames(
              "fa-2xl py-1 px-2 ",
              isActive
                ? isSituatedOnSidebar
                  ? "text-white surface-800 border-round-xl shadow-3"
                  : "text-primary bg-white border-round-xl shadow-3"
                : isSituatedOnSidebar
                ? "text-800"
                : "text-white"
            )}
          />
        </NavLink>
      </li>
    </>
  );
}
