import { useMutation, useQuery } from "react-query";
import { importsService } from "../services/imports.service";
import { Import } from "./models/import.model";

export function useGetAllImportsQuery() {
    return useQuery<Import[]>(["imports"], () =>
        importsService.getAll());
}
export function useGetByDateImportsQuery(dateFrom: Date | undefined, dateTo: Date | undefined) {
    return useQuery<Import[] | undefined>(
        ["imports", dateFrom, dateTo],
        () => importsService.getByDates(dateFrom!, dateTo!),
        { refetchOnWindowFocus: false, enabled: dateFrom !== undefined && dateTo !== undefined }
    );
}

export function useUploadXmlImportMutation() {
    return useMutation((model: Blob) => importsService.uploadImportFile(model));
}