import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";

interface FileUploadProps {
  inputKey: string;
  accept?: string;
  icon?: IconProp;
  capture?: boolean | "user" | "environment" | undefined;
  className?: string;
  label?: string;
  onFilesChoosen?: (files: FileList) => void;
}

export function FileUpload({
  inputKey,
  accept = "*",
  icon,
  capture,
  className,
  label,
  onFilesChoosen,
}: FileUploadProps) {
  const handleFileUpload = useCallback(
    async (e: any) => {
      if (onFilesChoosen) onFilesChoosen(e.target.files);
    },
    [onFilesChoosen]
  );
  return (
    <div className={className}>
      <label
        htmlFor={inputKey}
        className="custom-file-upload"
      >
        {label}
      </label>
      <input
        id={inputKey}
        type="file"
        onInput={handleFileUpload}
        accept={accept}
        capture={capture}
      />
    </div>
  );
}
