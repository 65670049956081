import { DeviceReading } from "../queries/models/device-reading.model.js";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class DevicesReadingsService extends BaseService {

    async getReadingsForSeries(fromDate: Date, toDate: Date, deviceIds: number[]): Promise<DeviceReading[]> {
        const params = {
            fromDate: fromDate.toISOString(),
            toDate: toDate.toISOString(),
            ids: deviceIds.join(',')
        };
        const url = `${this.endpointPath}/get-for-series`;
        return this.http.get<DeviceReading[]>(url, { params }).then((response: any) => response.data);
    }
}

export const devicesReadingsService = new DevicesReadingsService(API_ENDPOINTS.DEVICE_READINGS);