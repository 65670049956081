import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { SelectItem } from "primereact/selectitem";
import { Recorder } from "../queries/models/recorder.model";
import { Device } from "../queries/models/device.model.ts";

class DevicesService extends BaseService {

    async getAllActive(): Promise<Device[]> {
        const url = `${this.endpointPath}/active`;
        return this.http.get<Recorder[]>(url).then((response: any) => response.data);
    }

    async getAllActiveAsOptions(): Promise<SelectItem[]> {
        const url = `${this.endpointPath}/active-as-options`;
        return this.http.get<SelectItem[]>(url).then((response: any) => response.data);
    }
}

export const devicesService = new DevicesService(API_ENDPOINTS.DEVICES);