import { useQuery } from "react-query";
import { Device } from "./models/device.model.ts";
import { devicesService } from "../services/devices.service";

export function useDevicesQuery() {
    return useQuery<Device[]>(["devices"], () =>
        devicesService.getAllActive());
}
export function useActiveDevicesAsSelectOptionQuery() {
    return useQuery(
        ["devices", "devices-options"],
        () => devicesService.getAllActiveAsOptions()
    )
}