import { MenuItem } from "./bar-navigation";
import { BottomBarNavigationMobileItems } from "./bottom-bar-navigation-items-mobile";

export function BottomBarNavigationMobile({
  menuItems,
}: {
  menuItems: MenuItem[];
}) {
  return (
    <div className="flex fixed w-full h-4rem bottom-0 z-2 bg-primary">
      <div className="w-full p-1">
        <BottomBarNavigationMobileItems menuItems={menuItems} />
      </div>
    </div>
  );
}
