import { LoginRequest } from "../queries/models/login-request.model";
import { LoginResponse } from "../queries/models/login-response.model";
import { BaseService } from "./base.service";
import { LoggedUserInfo } from "../queries/models/_shared/logged-user-info";
import { API_ENDPOINTS } from "../utils/endpoints";
import { User } from "../queries/models/user.model";
import { UserRole } from "../queries/models/enums/user-role";
import { ResetPasswordRequest } from "../queries/models/reset-password-request.model";
import { ForgotPasswordRequest } from "../queries/models/forgot-password-request.model";
import { AUTH_USER_INFO } from "../constants/auth-user-info.constant";

class AuthService extends BaseService {

  async getUserByToken(token: string): Promise<User> {
    const url = `${this.endpointPath}/user-by-token/${token}`;
    return this.http.get<User>(url).then((response: any) => response.data);
  }

  async forgotPassword(request: ForgotPasswordRequest): Promise<any> {
    return this.http.post(`${this.endpointPath}/forgot-password`, request).then((res: any) => res.data);
  }
  async resetPassword(request: ResetPasswordRequest): Promise<any> {
    return this.http.post(`${this.endpointPath}/reset-password`, request).then((res: any) => res.data);
  }
  async login(loginRequest: LoginRequest): Promise<LoginResponse> {
    return this.http.post(`${this.endpointPath}/login`, loginRequest).then((res: any) => res.data);
  }

  getLoggedUser = (): LoggedUserInfo | null => {
    const valueString = localStorage.getItem(AUTH_USER_INFO);
    return valueString ? JSON.parse(valueString) : null;
  };

  setLoggedUser(info: LoggedUserInfo) {
    localStorage.setItem(AUTH_USER_INFO, JSON.stringify(info));
  }

  logout() {
    localStorage.removeItem(AUTH_USER_INFO);
  }

  isAdmin = (): boolean => {
    const loggedUser = this.getLoggedUser();
    return loggedUser?.isAdmin ?? false;
  };
}

export const authService = new AuthService(API_ENDPOINTS.AUTHENTICATION);