import { useMutation, useQuery } from 'react-query';
import { User } from './models/user.model';
import { usersService } from '../services/users.service';
import { CreateUserRequest } from './models/create-user-request.model';
import { UpdateUserRequest } from './models/update-user-request';

export function useUsersQuery() {
  return useQuery<User[]>(["users"], () =>
    usersService.getAllActiveUsers());
}

export function useActiveUsersAsSelectOptionQuery() {
  return useQuery(
    ["users", "users-options"],
    () => usersService.getAllActiveUsersAsSelectOptions()
  )
}

export function useAddUserMutation() {
  return useMutation((request: CreateUserRequest) => usersService.addUser(request));
}

export function useEditUserMutation() {
  return useMutation((request: UpdateUserRequest) => usersService.editUser(request));
}

export function useDeleteUserMutation() {
  return useMutation((id: number) => usersService.deleteUser(id));
}