import { useMemo } from "react";
import { SideBarNavigationDesktop } from "./side-bar-nav-desktop";
import { BottomBarNavigationMobile } from "./bottom-bar-nav-mobile";
import { useWindowSize } from "../../hooks/use-window-size";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faUserGroup,
  faDashboard,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import { authService } from "../../services/auth.service";

export interface MenuItem {
  to: string;
  label: string;
  icon: IconProp;
}

export function BarNavigation() {
  const isAdmin = useMemo(() => {
    return authService.isAdmin();
  }, []);
  const { lg } = useWindowSize() || {};

  const menuItems = useMemo(() => {
    const items = [];

    items.push({
      to: "dashboard",
      label: "Dashboard",
      icon: faDashboard,
    });

    if (isAdmin) {
      items.push({
        to: "imports",
        label: "Imports",
        icon: faFileImport,
      });

      items.push({
        to: "users",
        label: "Users",
        icon: faUserGroup,
      });
    }
    return items;
  }, [isAdmin]);

  return lg ? (
    <SideBarNavigationDesktop menuItems={menuItems} />
  ) : (
    <BottomBarNavigationMobile menuItems={menuItems} />
  );
}
