
import { Import } from "../queries/models/import.model";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class ImportsService extends BaseService {

    async getAll(): Promise<Import[]> {
        const url = `${this.endpointPath}/get-all`;
        return this.http.get<Import[]>(url).then((response: any) => response.data);
    }

    async getByDates(from: Date, to: Date): Promise<Import[]> {
        const url = `${this.endpointPath}/get-all/${from.toISOString()}/${to.toISOString()}`;
        return this.http.get<Import[]>(url).then((response: any) => response.data);
    }

    async uploadImportFile(file: Blob): Promise<any> {
        let url = `${this.endpointPath}/xml-fieldgate-file`;
        const formData = new FormData();
        formData.append("file", file);

        return this.http
            .post<any>(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => res.data);
    }
}

export const importsService = new ImportsService(API_ENDPOINTS.IMPORT);