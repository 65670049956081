import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { useCallback, useMemo, useState } from "react";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { Panel } from "primereact/panel";
import { endOfMonth, format, startOfMonth, subDays } from "date-fns";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Enumerable from "linq";
import {
  useGetByDateImportsQuery,
  useUploadXmlImportMutation,
} from "../../queries/imports.query";
import { useToast } from "../../components/ui/toast-context-provider";
import { FileUpload } from "../../components/ui/file-upload/file-upload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileInfo } from "../../utils/file-info";
import { Button } from "primereact/button";

export function Imports() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const [filters, setFilters] = useState({
    recorderTag: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    dateOfImport: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    dateOfContent: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });
  const [selectedFile, setSelectedFile] = useState<FileInfo>();

  const [dateRange, setDateRange] = useState<Date[]>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);

  const importsQuery = useGetByDateImportsQuery(dateRange[0], dateRange[1]);
  const uploadMutation = useUploadXmlImportMutation();

  const data = useMemo(() => {
    return importsQuery.data?.map((i) => ({
      id: i.id,
      recorderTag: i.recorderTag,
      dateOfImport: new Date(i.dateOfImport),
      dateOfContent: new Date(i.dateOfContent),
      isSuccess: i.isSuccess ? "Yes" : "No",
    }));
  }, [importsQuery.data]);

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const recorderOptions = useMemo(() => {
    const result = Enumerable.from(data ?? [])
      .select((x) => x.recorderTag)
      .distinct()
      .select((x) => ({ label: x, value: x }))
      .toArray();
    return result;
  }, [data]);

  const recorderRowFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <MultiSelect
        options={recorderOptions}
        value={options.value}
        onChange={(e) => {
          options.filterApplyCallback(e.value, options.index);
        }}
        placeholder={"Recorder"}
        maxSelectedLabels={1}
      />
    ),
    [recorderOptions]
  );

  const handleFilesChoosen = useCallback((files: FileList) => {
    var chosenFiles = Array.from(files).map((file) => {
      let fi = {
        value: null,
        name: file.name,
        size: file.size,
        blob: file,
        type: file.type,
      } as FileInfo;

      return fi;
    });

    setSelectedFile(chosenFiles[0]);
  }, []);

  const handleUpload = useCallback(() => {
    const mutateOptions = {
      onSuccess: async () => {
        toast.current?.show({
          severity: "success",
          detail: "Save success",
        });
        await queryClient.invalidateQueries("imports");
        setSelectedFile(undefined);
      },
      onError: async (error: any) => {
        debugger;
        toast.current?.show({
          severity: "error",
          detail: error?.data,
        });
      },
    };

    return uploadMutation.mutateAsync(selectedFile?.blob!, mutateOptions);
  }, [selectedFile, uploadMutation, queryClient, toast]);

  return (
    <div className="h-full w-full">
      <div className="border-1 border-300 h-full w-full flex flex-column">
        <div className="w-full flex flex-row">
          <div className="">
            <Calendar
              className="p-2"
              style={{ width: "300px" }}
              value={dateRange}
              onChange={(e) => setDateRange(e.value as Date[])}
              selectionMode="range"
              showButtonBar
              onClearButtonClick={() =>
                setDateRange([subDays(new Date(), 14), new Date()])
              }
            />
          </div>
          <div className="w-full flex flex-row align-content-center justify-content-end p-2">
            <FileUpload
              inputKey="file-upload"
              accept=".xml"
              label={selectedFile ? selectedFile.name : "Choose file"}
              onFilesChoosen={handleFilesChoosen}
              className="pr-2"
            />

            {selectedFile && (
              <Button
                label="Upload"
                onClick={() => {
                  handleUpload();
                }}
              />
            )}
          </div>
        </div>
        <div className="h-full w-full flex">
          <DataTable
            value={data ?? []}
            loading={importsQuery.isFetching}
            scrollable={true}
            scrollHeight="calc(100% - 55px)"
            dataKey="id"
            className="w-full"
            filters={filters}
            globalFilterFields={[
              "recorderTag",
              "dateOfImport",
              "dateOfContent",
            ]}
          >
            <Column
              field="recorderTag"
              filter={true}
              sortable
              header="Recorder"
              filterElement={recorderRowFilterTemplate}
              showFilterMenu={true}
              showClearButton={true}
              style={{ width: "30%" }}
            ></Column>
            <Column
              field="dateOfImport"
              filterField="dateOfImport"
              filter={true}
              sortable
              dataType="date"
              header="Date of import"
              filterElement={dateFilterTemplate}
              showFilterMenu={true}
              showClearButton={true}
              filterMatchMode="custom"
              style={{ width: "30%" }}
              body={(rowData) =>
                format(new Date(rowData.dateOfImport), "dd/MM/yyyy HH:mm")
              }
            ></Column>
            <Column
              field="dateOfContent"
              filterField="dateOfContent"
              filter={true}
              sortable
              dataType="date"
              header="Date of content"
              filterElement={dateFilterTemplate}
              showFilterMenu={true}
              showClearButton={true}
              filterMatchMode="custom"
              body={(rowData) =>
                format(new Date(rowData.dateOfContent), "dd/MM/yyyy HH:mm")
              }
              style={{ width: "30%" }}
            ></Column>
            <Column
              field="isSuccess"
              filter={true}
              sortable
              header="Success"
              showFilterMenu={false}
              showClearButton={false}
              filterMatchMode="custom"
              style={{ width: "10%" }}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
}
