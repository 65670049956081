import { ATOM_SIDEBAR, ATOM_SIDEBAR_MORE } from "../utils/local-storage-keys";
import { atomWithLocalStorage } from "./atom-with-storage";

export const sideBarAtom = atomWithLocalStorage(ATOM_SIDEBAR, {
  expanded: true,
});

export const sideBarMoreAtom = atomWithLocalStorage(ATOM_SIDEBAR_MORE, {
  expanded: true,
});
