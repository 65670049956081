import { useEffect, useMemo, useRef } from "react";
import Enumerable from "linq";
import { getUnixTime, min } from "date-fns";
import { TimeSpan } from "../../utils/timespan";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import { Device } from "../../queries/models/device.model.ts";
import { DeviceReading } from "../../queries/models/device-reading.model";
import { off } from "process";
import { RandomGenerator } from "../../utils/random-generator";

export interface DevicesChartComponentProps {
  className?: string;
  devices: Device[];
  deviceReadings: DeviceReading[];
}

export function DevicesChartComponent({
  devices,
  deviceReadings,
  className = "w-full h-full p-0 m-0",
}: DevicesChartComponentProps) {
  const chartOptions = useMemo(() => {
    let yAxises = Enumerable.from(devices)
      .groupBy((x) => x.uoM)
      .select((x, i) => {
        const readings = Enumerable.from(deviceReadings).where(
          (r) => r.deviceId === x.first().id
        );
        return {
          type: "value",
          name: x.key(),
          position: "left",
          offset: i * 60,
          axisLine: {
            lineStyle: {
              color: RandomGenerator.getSequencedColor(i),
            },
          },
          min: readings.any() ? readings.min((x) => x.value) : 0,
          max: readings.any() ? readings.max((x) => x.value) : 100,
        };
      })
      .toArray();

    if (yAxises.length === 0) {
      yAxises.push({
        type: "value",
        name: "Value",
        position: "left",
        offset: 0,
        min: 0,
        max: 100,

        axisLine: {
          lineStyle: {
            color: "#000",
          },
        },
      });
    }

    const series = Enumerable.from(devices)
      .select((device) => {
        const readings = Enumerable.from(deviceReadings).where(
          (r) => r.deviceId === device.id
        );

        if (readings.count() === 0) {
          return null;
        }

        const data = readings
          .select((r) => ({
            value: [getUnixTime(r.date) * 1000, r.value],
            unit: device.uoM,
          }))
          .toArray();

        return {
          name: device.tag,
          type: "line",
          data: data,
          yAxisIndex: yAxises.findIndex((x) => x.name === device.uoM),
        };
      })
      .where((x) => x !== null)
      .toArray();

    const formatter = function (params: any, _ticket: any, _callback: any) {
      if (params instanceof Array) {
        if (params.length) {
          let message = "";
          message += `<b>${params[0].axisValueLabel}</b>`;
          params.forEach((param) => {
            message +=
              `<br/>${param.marker}${param.seriesName} ` +
              `<b>${(+param.data.value[1]).toFixed()} ${
                param.data.unit || ""
              }</b>`;
          });
          return message;
        } else {
          return null;
        }
      } else {
        let message = "";
        message += `${params[0].axisValueLabel}`;
        message += `<br/>${params.marker}${params.seriesName}: ${params.value}${
          params.data.unit || ""
        }`;
        return message;
      }
    };

    return {
      grid: {
        top: 100,
        right: 50,
        bottom: 100,
        left: Enumerable.from(yAxises).max((x) => x.offset) + 80,
      },
      xAxis: {
        type: "time",
      },
      yAxis: yAxises,
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
        },
      ],
      series: series,
      tooltip: {
        show: true,
        trigger: "axis",
        formatter: formatter,
      },
      axisPointer: {
        lineStyle: {
          color: "#000",
          width: 2.4,
          shadowColor: "#FFF",
          shadowBlur: 3,
        },
      },
    } as EChartsOption;
  }, [deviceReadings, devices]);

  return (
    <ReactECharts
      className={className}
      option={chartOptions}
      notMerge={true}
      lazyUpdate={true}
    />
  );
}
