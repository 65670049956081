import { atom, PrimitiveAtom } from 'jotai';

type Setter<T> = (current: T) => T;

export const atomWithLocalStorage = <T>(key: string, initialValue: T): PrimitiveAtom<T> => {
    const getInitialValue = () => {
        const item = localStorage.getItem(key);
        if (item !== null) {
            return JSON.parse(item) as T;
        }
        return initialValue;
    };
    const baseAtom = atom(getInitialValue());
    const derivedAtom = atom(
        (get) => get(baseAtom),
        (get,set, update) => {
            const nextValue = typeof update === 'function' ? (update as Setter<T>)(get(baseAtom)) : update as Setter<T>;
            set(baseAtom, nextValue);
            localStorage.setItem(key, JSON.stringify(nextValue));
        }
    );
    return derivedAtom;
}